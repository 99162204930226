import React, { useContext } from "react";
import qs from "qs";
import moment from "moment";
import SimpleReactValidator from "simple-react-validator";
import FlightService from "../../Service/FlightService";
import { TripSummary } from "./TripSummary";
import FlightLoader from "../../DefaultComponent/FlightLoaderCommon";
import { notification } from "../../Shared/NotificationModal";
import FlightDetailsContext from "../../Context/Context";
import SearchResult from "../SearchResult/SearchResult";
import { loadStripe } from "@stripe/stripe-js";
import RefreshModal from "../Popup/RefreshPopupFlightDetails";
import { CABIN_OPTIONS } from "../SearchResult/New/constants";
import PriceGuarantee from "./PriceGuarantee";
import CheckInBaggage from "./CheckInBaggage";
import BaggageNotification from "./BaggageNotification";

const flightServ = new FlightService();

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
class FlightDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: "false",
      getFlightDetails: {},
      tc: false,
      AmaNode: [],
      clientRef: "",
      acceptTC: false,
      cheapestData: {},
      showPopup: false,
      addBaggage: false,
      baggagePrice: 0,
      baggageNotification: false,
      baggageBasePrice: 89.99,
    };
    this.tncRef = React.createRef();
    this.validator = new SimpleReactValidator();
    this.inactivityTimeout = null;
  }

  componentDidMount() {
    this.setState({
      loading: "true",
    });
    this.setInactivityTimer();
    window.addEventListener("mousemove", this.resetInactivityTimer);
    window.addEventListener("keydown", this.resetInactivityTimer);
    const { history, location } = this.props;
    if (location.pathname.includes("googleresults")) {
      const searchParams = new URLSearchParams(location.search);
      const displayedPrice = searchParams.get("DisplayedPrice");
      const adult = searchParams.get("Adult");
      const child = searchParams.get("Child");
      const infant = searchParams.get("Infant");
      if (!sessionStorage.getItem("displayedPrice")) {
        sessionStorage.setItem("displayedPrice", displayedPrice);
      }
      if (!sessionStorage.getItem("adult")) {
        sessionStorage.setItem("adult", adult);
      }
      if (!sessionStorage.getItem("child")) {
        sessionStorage.setItem("child", child);
      }
      if (!sessionStorage.getItem("infant")) {
        sessionStorage.setItem("infant", infant);
      }
      if (displayedPrice !== null) {
        searchParams.delete("DisplayedPrice");
        searchParams.delete("Adult");
        searchParams.delete("Child");
        searchParams.delete("Infant");
        history.replace({
          pathname: location.pathname,
          search: decodeURIComponent(searchParams.toString()),
        });
      }
      this.getGoogleFlightDetails();
    } else {
      this.getAmadeusFlightDetails();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.flightDetails !== prevState.flightDetails) {
      window.dataLayer = window.dataLayer || [];
      if (this.state.flightDetails) {
        const { flight_details } = this.state.flightDetails;
        const addBaggageExists = new URLSearchParams(
          this.props.location.search
        ).has("addBaggage");
        this.setState({
          addBaggage: addBaggageExists,
          baggagePrice: Number(
            (
              this.state.baggageBasePrice *
              ((flight_details?.bookingInfo?.Adults || 0) +
                (flight_details.bookingInfo.Children || 0))
            ).toFixed(2)
          ),
        });
        window.dataLayer.push({
          event: "view_item",
          value: flight_details?.totalFare?.amount?.toString() || "0.00",
          currency: flight_details?.totalFare?.currency || "GBP",
          flight_error_code: "",
        });
      } else {
        window.dataLayer.push({
          event: "view_item",
          flight_error_code: "FLIGHT_NOT_FOUND",
          value: "",
          flight_error_message: `Sorry, there aren't any flights that match your filters`,
        });
      }
    }
  }

  componentWillUnmount() {
    clearTimeout(this.inactivityTimeout);
    window.removeEventListener("mousemove", this.resetInactivityTimer);
    window.removeEventListener("keydown", this.resetInactivityTimer);
  }

  setInactivityTimer = () => {
    this.inactivityTimeout = setTimeout(() => {
      this.setState({ showPopup: true });
    }, 900000);
  };

  resetInactivityTimer = () => {
    clearTimeout(this.inactivityTimeout);
    this.setInactivityTimer();
  };

  goBack = () => {
    this.props.history.goBack();
  };

  getGoogleFlightDetails = async () => {
    const data = {
      req: this.props.location.search.replace("?", ""),
    };

    const searchParams = new URLSearchParams(this.props.location.search);
    const searchParamsObject = {};
    searchParams.forEach((value, key) => {
      searchParamsObject[key] = value;
    });
    const newData = {
      ...searchParamsObject,
      DisplayedPrice: sessionStorage.getItem("displayedPrice"),
      Adult: sessionStorage.getItem("adult"),
      Child: sessionStorage.getItem("child"),
      Infant: sessionStorage.getItem("infant"),
    };
    const newDataString = {
      req: decodeURIComponent(new URLSearchParams(newData).toString()),
    };

    // const searchInfo = qs.parse(this.props.location.search?.substring(1))
    //   ?.getFlightInfo?.searchinfo;
    const flightOffer = [];
    flightOffer.push(
      this.props.bookedFlightDetail?.data[0]?.AmaNode ||
      JSON.parse(sessionStorage.getItem("flightDetails"))?.flightOffers
    );
    if (newDataString) {
      this.setState({
        loading: "true",
      });
      await flightServ
        .getGoogleFlightDetails(newDataString)
        .then(async (res) => {
          let leg = res.FlightChoice.Leg;
          let airportCodes = Array.isArray(leg) ? leg : [leg];
          const setData = new Set();
          const airlineData = new Set();
          const airlineDataCheapest = new Set();
          let airlineResultCheapest = {};
          airportCodes.map(({ From, To, Carrier }) => {
            setData.add(From);
            setData.add(To);
            airlineData.add(Carrier);
          });
          const result = await flightServ.ListgetAirportCode([...setData]);
          const airlineResult = await flightServ.getAirLineCode([
            ...airlineData,
          ]);

          if (res?.Cheapest?.Airline?.length) {
            airlineDataCheapest.add(res?.Cheapest?.Airline?.[0]);
            airlineResultCheapest = await flightServ.getAirLineCode([
              ...airlineDataCheapest,
            ]);
            this.setState({
              cheapestData: {
                airlineName: airlineResultCheapest?.[0]?.name,
                price: res?.Cheapest?.price,
                requestId: res?.Cheapest?.requestId,
                // isDirect: res?.Cheapest?.isDirect
              },
            });
          }

          /* eslint-disable no-unused-expressions */
          this.setState({
            flightDetails: {
              //...res.Reply.ShoppingBasketReply,
              ...res,
              airportName: result,
              airlineName: airlineResult,
              loading: "false",
            },
            AmaNode: res.flightOffers,
            clientRef: res.clientRef,
          });
          //for state persistence on page reload
          sessionStorage.setItem(
            "flightDetails",
            JSON.stringify({
              ...res,
              airportName: result,
              airlineName: airlineResult,
              loading: "false",
            })
          );
        })
        .catch((e) =>
          notification(
            "error",
            "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!"
          )
        )
        .finally(() =>
          this.setState({
            loading: "false",
          })
        );
    }
  };

  getFlightDetails = async () => {
    const data = qs.parse(this.props.location.search?.substring(1));
    const ruleBody = {
      reference: data?.getFlightInfo?.itemref,
      type: "F",
    };
    if (data) {
      this.setState({
        loading: "true",
      });
      await flightServ
        .getFlightAirRule(ruleBody)
        .then((res) =>
          this.setState({ fareRule: res.Reply?.GetAirRulesReply?.RuleDetails })
        );
      await flightServ
        .getFlightDetails(data.getFlightInfo)
        .then(async (res) => {
          let leg = res.Reply.ShoppingBasketReply.FlightChoice.Leg;
          let airportCodes = Array.isArray(leg) ? leg : [leg];
          const setData = new Set();
          const airlineData = new Set();
          airportCodes.map(({ From, To, Carrier }) => {
            setData.add(From);
            setData.add(To);
            airlineData.add(Carrier);
          });
          const result = await flightServ.ListgetAirportCode([...setData]);
          const airlineResult = await flightServ.getAirLineCode([
            ...airlineData,
          ]);
          /* eslint-disable no-unused-expressions */
          this.setState({
            flightDetails: {
              ...res.Reply.ShoppingBasketReply,
              airportName: result,
              airlineName: airlineResult,
              loading: "false",
            },
          });
        })
        .catch((e) =>
          notification(
            "error",
            "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!",
            this.goBack
          )
        )
        .finally(() =>
          this.setState({
            loading: "false",
          })
        );
    }
  };

  getAmadeusFlightDetails = async () => {
    const searchInfo = qs.parse(this.props.location.search?.substring(1))
      ?.getFlightInfo.searchinfo;
    const flightOffer = [];
    flightOffer.push(
      this.props.bookedFlightDetail?.data[0]?.AmaNode ||
      JSON.parse(sessionStorage.getItem("flightDetails"))?.flightOffers
    );
    const data = {
      data: {
        type: "flight-offers-pricing",
        flightOffers: flightOffer,
      },
      body: searchInfo,
    };

    if (data) {
      this.setState({
        loading: "true",
      });
      await flightServ
        .getFlightDetails(data)
        .then(async (res) => {
          let leg = res.FlightChoice.Leg;
          let airportCodes = Array.isArray(leg) ? leg : [leg];
          const setData = new Set();
          const airlineData = new Set();
          airportCodes.map(({ From, To, Carrier }) => {
            setData.add(From);
            setData.add(To);
            airlineData.add(Carrier);
          });
          const result = await flightServ.ListgetAirportCode([...setData]);
          const airlineResult = await flightServ.getAirLineCode([
            ...airlineData,
          ]);
          /* eslint-disable no-unused-expressions */
          this.setState({
            flightDetails: {
              //...res.Reply.ShoppingBasketReply,
              ...res,
              airportName: result,
              airlineName: airlineResult,
              loading: "false",
            },
            AmaNode: res.flightOffers,
            clientRef: res.clientRef,
          });
          //for state persistence on page reload
          sessionStorage.setItem(
            "flightDetails",
            JSON.stringify({
              ...res,
              airportName: result,
              airlineName: airlineResult,
              loading: "false",
            })
          );
        })
        .catch((e) =>
          notification(
            "error",
            "Oops something went wrong at our end and we know this is embarrassing. We are working on it, please try again later!",
            this.goBack
          )
        )
        .finally(() =>
          this.setState({
            loading: "false",
          })
        );
    }
  };

  handleTcChange = (e, value) => {
    this.setState({ tc: e.target.checked });
    this.setState({
      acceptTC: e.target.checked ? false : true,
    });
  };

  handleBookFlight = async () => {
    if (this.validator.allValid() && this.state.tc) {
      const {
        flight_details,
        FlightChoice,
        BookingInfo,
        PricingInfo,
        sessionId,
      } = this.state.flightDetails || {};

      let flightDataList = [];
      flight_details.inwardSegment?.forEach((flightDetails) => {
        flightDataList.push({
          travel_class: flightDetails.cabin,
          fare_product: "",
          booking_code: flightDetails.class,
          flight_number: flightDetails.flight_number,
          origin: flightDetails.departure.iataCode,
          destination: flightDetails.arrival.iataCode,
          start_date: moment(flightDetails.departure.dateTime).format(
            "YYYY-MM-DD"
          ),
        });
      });

      flight_details.outwardSegment.forEach((flightDetails) => {
        flightDataList.push({
          travel_class: flightDetails.cabin,
          fare_product: "",
          booking_code: flightDetails.class,
          flight_number: flightDetails.flight_number,
          origin: flightDetails.departure.iataCode,
          destination: flightDetails.arrival.iataCode,
          start_date: moment(flightDetails.departure.dateTime).format(
            "YYYY-MM-DD"
          ),
        });
      });

      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        value: flight_details?.totalFare?.amount?.toString() || "0.00",
        currency: flight_details?.totalFare?.currency || "GBP",
        trip_type: flight_details?.type,
        passengers_total:
          (flight_details?.bookingInfo?.Adults || 0) +
          (flight_details?.bookingInfo?.Children || 0) +
          (flight_details?.bookingInfo?.Infants || 0),
        passengers_adult: flight_details?.bookingInfo?.Adults || 0,
        passengers_child: flight_details?.bookingInfo?.Children || 0,
        passengers_infant_in_seat: flight_details?.bookingInfo?.Infants || 0,
        passengers_infant_in_lap: 0,
        items: [...flightDataList],
      });

      const data = qs.parse(this.props.location.search?.substring(1));
      if (!this.state.tc) {
        notification("error", "Please accept our terms and conditions.");
        return;
      }
      if (!this.state.email) {
        notification("error", "Please enter email of Traveller");
        return;
      }
      const requestData = {
        flightDetail: { ...flight_details, PricingInfo },
        session_id: sessionId,
        email: this.state.email,
        contact: this.state.contact,
        adult: BookingInfo?.Adults,
        child: BookingInfo?.Children,
        infant: BookingInfo?.Infants,
        clientRef: this.state.clientRef,
        utm_campaign: data?.getFlightInfo?.searchinfo.utm_campaign,
      };

      const firstLeg = FlightChoice?.Leg[0];
      const lastLeg = FlightChoice.Leg[FlightChoice?.Leg.length - 1];
      if (firstLeg && lastLeg) {
        requestData.from = data?.getFlightInfo?.searchinfo.from
          ? data?.getFlightInfo?.searchinfo.from
          : data?.Origin1;
        requestData.to = data?.getFlightInfo?.searchinfo.to
          ? data?.getFlightInfo?.searchinfo.to
          : data?.Origin2;
        requestData.type = flight_details.type;
        requestData.flightDetail.offerPrice = (
          Number(requestData.flightDetail.offerPrice) +
          (this.state.addBaggage ? this.state.baggagePrice : 0)
        ).toString();
        requestData.flightDetail.totalFare.amount = (
          Number(requestData.flightDetail.totalFare.amount) +
          (this.state.addBaggage ? this.state.baggagePrice : 0)
        ).toString();
        if (this.state.addBaggage) {
          requestData.flightDetail.baggagePrice = this.state.baggagePrice;
        }
        requestData.departure_date = moment(
          BookingInfo.FirstDate,
          "YYYY-MM-DDTHH:mm:ssZ"
        ).format("YYYY-MM-DD");
        if (flight_details.type === "RoundTrip") {
          requestData.arrival_date = moment(
            BookingInfo.LastDate,
            "YYYY-MM-DDTHH:mm:ssZ"
          ).format("YYYY-MM-DD");
        } else {
          requestData.arrival_date = null;
        }
        var totalPassengers = BookingInfo.Adults;
        totalPassengers += BookingInfo.Children ? BookingInfo.Children : 0;
        totalPassengers += BookingInfo.Infants ? BookingInfo.Infants : 0;
        requestData.details = BookingInfo
          ? [...Array(totalPassengers).keys()].map((_, i) => ({
            Firstname: this.state[`details.${i}.Firstname`],
            Surname: this.state[`details.${i}.Surname`],
            Title: this.state[`details.${i}.Title`] || "Mr",
            DOB: this.state[`details.${i}.DOB`]
              ? moment(this.state[`details.${i}.DOB`])
                .format("DD-MM-YYYY")
                .split("-")
                .join("")
              : "",
            PaxType: this.state[`details.${i}.paxType`],
            AmaDOB: this.state[`details.${i}.DOB`]
              ? moment(this.state[`details.${i}.DOB`]).format("YYYY-MM-DD")
              : "",
          }))
          : [];
      } else {
        return;
      }
      let travelers = [];
      requestData.details.map((item, index) => {
        var traveler = {
          id: "",
          dateOfBirth: "",
          name: {
            firstName: "",
            lastName: "",
          },
          contact: {
            emailAddress: "",
            phones: [
              {
                deviceType: "",
                number: "",
              },
            ],
          },
        };
        (traveler.id = index + 1),
          (traveler.dateOfBirth = item.AmaDOB),
          (traveler.name.firstName = item.Firstname),
          (traveler.name.lastName = item.Surname),
          (traveler.contact.emailAddress = requestData.email),
          (traveler.contact.phones[0].deviceType = "MOBILE"),
          (traveler.contact.phones[0].number = requestData.contact);

        travelers.push(traveler);
      });
      requestData.AmaNode = {
        data: {
          type: "flight-order",
          flightOffers: [this.state.AmaNode],
          travelers: travelers,
        },
      };
      let isError = false;
      requestData.details.map((item) => {
        if (isError) return;
        Object.values(item).map((value) => {
          if (!value) {
            isError = true;
          }
        });
      });
      if (isError) {
        notification("error", "Please enter all Travellers details");
        return;
      }
      this.setState({
        loading: "true",
      });
      await flightServ
        .bookFlight(requestData)
        .then(async (res) => {
          this.setState({
            loading: "false",
          });
          // console.log("booking flight res:", res);
          if (res.stripe_session) {
            // console.log("in stripe");
            const stripe = await stripePromise;

            // When the customer clicks on the button, redirect them to Checkout.
            const result = await stripe.redirectToCheckout({
              sessionId: res.stripe_session,
            });

            if (result.error) {
              // If `redirectToCheckout` fails due to a browser or network
              // error, display the localized error message to your customer
              // using `result.error.message`.
              notification("error", result.error.message);
            }

            notification("success", "Flight Booked Successfully.");
            this.props.history.push({
              pathname: "/thankyou",
              search: `?${qs.stringify({
                bookingId: res.Reply.Reply.BookingResponse.BookingId,
              })}`,
            });
          } else {
            // console.log("NOT in stripe");

            this.props.history.push({
              pathname: "/thanks",
              search: `?${qs.stringify({
                bookingId: res.id,
              })}`,
            });
          }

          // this.props.history.push("/user/history")
        })
        .catch((e) => {
          notification(
            "error",
            "Oops, something went wrong. Please contact our customer service at <a href='tel:'" +
            this.props.SettingList.contact_phone +
            ">" +
            this.props.SettingList.contact_phone +
            "</a> for further assistance."
          );
        })
        .finally(() =>
          this.setState({
            loading: "false",
          })
        );
    } else {
      this.validator.showMessages();
      this.forceUpdate();
      if (
        this.validator.errorMessages?.email ||
        this.validator.errorMessages?.contact
      ) {
        if (!this.state.tc) {
          this.setState({
            acceptTC: true,
          });
        }
        const inputIds = {
          email: "email",
          contact: "contact",
        };
        const errorMessages = this.validator.errorMessages;
        for (const errorMessage in errorMessages) {
          if (errorMessages[errorMessage]) {
            const inputId = inputIds[errorMessage];
            const inputElement = document.getElementById(inputId);
            inputElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            setTimeout(() => {
              inputElement.focus();
            }, 400);
            break;
          }
        }
      } else if (
        this.validator.errorMessages?.Firstname ||
        this.validator.errorMessages?.Lastname ||
        this.validator.errorMessages["Date of birth"]
      ) {
        if (!this.state.tc) {
          this.setState({
            acceptTC: true,
          });
        }
        const { Adults, Children, Infants } =
          this.state.flightDetails.BookingInfo;
        let travelerCount =
          (Adults ? Number(Adults) : 0) +
          (Children ? Number(Children) : 0) +
          (Infants ? Number(Infants) : 0);
        const inputIds = {};
        for (let i = 0; i < travelerCount; i++) {
          inputIds[`details.${i}.Firstname`] = `details.${i}.Firstname`;
          inputIds[`details.${i}.Surname`] = `details.${i}.Surname`;
          inputIds[`details.${i}.DOB`] = `details.${i}.DOB`;
        }
        const fields = ["Firstname", "Surname", "DOB"];
        let firstEmptyFieldIndex = -1;
        for (let i = 0; i < travelerCount; i++) {
          let emptyField = null;

          for (const field of fields) {
            const key = `details.${i}.${field}`;
            const value = this.state[key];

            if (!value || value.trim() === "") {
              emptyField = field;
              break;
            }

            if (
              (field === "Firstname" || field === "Surname") &&
              !/^[A-Za-z\s]+$/.test(value)
            ) {
              emptyField = field;
              break;
            }
          }

          if (emptyField) {
            firstEmptyFieldIndex = i;
            break;
          }
        }

        if (firstEmptyFieldIndex !== -1) {
          const field = fields.find((f) => {
            const key = `details.${firstEmptyFieldIndex}.${f}`;
            const value = this.state[key];

            return (
              !value ||
              value.trim() === "" ||
              ((f === "Firstname" || f === "Surname") &&
                !/^[A-Za-z\s]+$/.test(value))
            );
          });

          const inputId = inputIds[`details.${firstEmptyFieldIndex}.${field}`];
          const inputElement = document.getElementById(inputId);

          if (inputElement) {
            inputElement.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
            setTimeout(() => {
              inputElement.focus();
            }, 400);
          }
        }
      } else if (!this.state.tc) {
        const inputElement = document.getElementById("terms-condition");
        inputElement.scrollIntoView({ behavior: "smooth", block: "center" });
        this.setState({
          acceptTC: true,
        });
      }
    }
  };

  handleInfoChange = (e, paxType, i) => {
    this.setState({
      [e.target.name]: e.target.value,
      [`details.${i}.paxType`]: paxType,
    });
  };

  handleInfoChangeContact = (e) => {
    this.setState({
      contact: e.target.value.replace(/[^0-9]/g, ""),
    });
  };

  userInfo = (type, i, paxType, subIndex) => {
    const firstAdult = type === "Adult" && i === 0;
    let beforeDate = moment();
    const flightDetail = this.state.flightDetails?.flight_details;
    const outwardDateTime =
      flightDetail?.outwardSegment?.[0]?.departure?.dateTime;
    const inwardDateTime = flightDetail?.inwardSegment?.[0]?.arrival?.dateTime;
    if (flightDetail?.type === "SingleTrip") {
      beforeDate = moment(outwardDateTime);
    } else {
      beforeDate = moment(inwardDateTime);
    }
    if (type === "Adult") {
      beforeDate.subtract(12, "years");
    } else if (type === "Children") {
      beforeDate.subtract(2, "years");
    } else if (type === "Infants") {
      beforeDate = moment(
        type === "SingleTrip" ? outwardDateTime : inwardDateTime
      );
    }

    return (
      <>
        <form className="_pt-10 _w-100pct _one-line-flex ">
          <p className="list-form_label _pb-5">
            {" "}
            {firstAdult ? "Lead Passenger" : `${type} ${subIndex + 1}`}
          </p>
          <div class="form-group _pr-5 _pl-5 _pb-5">
            <label for="titleSelect">
              Title <span className="red-mask">*</span>
            </label>
            <select
              class="form-control"
              id="titleSelect"
              name={`details.${i}.Title`}
              onChange={(e) => this.handleInfoChange(e, paxType, i)}
            >
              <option value="Mr">Mr</option>
              <option value="Mrs">Mrs</option>
              <option value="Miss">Miss</option>
              <option value="Ms">Ms</option>
              <option value="Mstr"> Mstr </option>
            </select>
          </div>
          <div className="form-group _pr-5 _pl-5 _pb-5">
            <label htmlFor="name" className="label-text">
              First Name <span className="red-mask">*</span>
            </label>
            <input
              type="text"
              name={`details.${i}.Firstname`}
              id={`details.${i}.Firstname`}
              className="form-control"
              placeholder="Enter first name"
              onChange={(e) => this.handleInfoChange(e, paxType, i)}
            />
            {this.validator.message(
              `Firstname`,
              this.state[`details.${i}.Firstname`],
              "required|alpha_space",
              { messages: { required: "First Name is required" } },
              { className: "text-danger" }
            )}
          </div>
          <div className="form-group _pr-5 _pl-5 _pb-5">
            <label htmlFor="name" className="label-text">
              Last Name <span className="red-mask">*</span>
            </label>
            <input
              type="text"
              name={`details.${i}.Surname`}
              id={`details.${i}.Surname`}
              className="form-control"
              placeholder="Enter last name"
              onChange={(e) => this.handleInfoChange(e, paxType, i)}
            />
            {this.validator.message(
              `Lastname`,
              this.state[`details.${i}.Surname`],
              "required|alpha_space",
              { messages: { required: "Last Name is required" } },
              { className: "text-danger" }
            )}
          </div>
          <div className="form-group _pr-5 _pl-5 _pb-5">
            <label htmlFor="dob" className="label-text">
              Date of Birth <span className="red-mask">*</span>
            </label>

            <input
              type="date"
              id={`details.${i}.DOB`}
              name={`details.${i}.DOB`}
              className="form-control"
              placeholder="Enter date of birth"
              min={
                type === "Infants"
                  ? beforeDate
                    .clone()
                    .subtract(2, "years")
                    .add(1, "day")
                    .format("YYYY-MM-DD")
                  : type === "Children"
                    ? beforeDate
                      .clone()
                      .subtract(10, "years")
                      .add(1, "day")
                      .format("YYYY-MM-DD")
                    : ""
              }
              max={beforeDate.format("YYYY-MM-DD")}
              onChange={(e) => this.handleInfoChange(e, paxType, i)}
            />
            {this.validator.message(
              `Date of birth`,
              this.state[`details.${i}.DOB`] &&
              moment(this.state[`details.${i}.DOB`], "YYYY-MM-DD"),
              ["required", "date", { before_or_equal: beforeDate }],
              { messages: { required: "Date of birth is required" } },
              { className: "text-danger" }
            )}
          </div>
          {/*<div className="form-group _pb-10">
                           <label htmlFor="city" className="label-text">City:</label>
                                  <input type="text" className="form-control" id="city"
                                                                   placeholder="Enter city"
                                                                   name="city"/>
                                                        </div>
                                                        <div className="form-group _pb-10">
                                                            <label htmlFor="postal_code" className="label-text">Postal
                                                                code:</label>
                                                            <input type="text"
                                                                   className="form-control" id="postal_code"
                                                                   placeholder="Enter postal code"
                                                                   name="postal_code"/>
                                                        </div>
                                                        <div className="form-group _pb-10">
                                                            <label htmlFor="country" className="label-text">Select
                                                                country:</label>
                                                            <select className="form-control" id="country">
                                                                <option>America</option>
                                                                <option>India</option>
                                                                <option>London</option>
                                                                <option>NewYork</option>
                                                            </select>
                                                        </div>*/}
        </form>
      </>
    );
  };

  showTnc = () => {
    const node = this.tncRef.current;
    if (node.style.display === "block") node.style.display = "none";
    else node.style.display = "block";
  };

  addBaggageFunc = (e) => {
    e.preventDefault();
    this.setState(
      (prevState) => ({
        addBaggage: !prevState.addBaggage,
        baggageNotification: true,
      }),
      this.updateURL
    );
  };

  updateURL = () => {
    const currentSearchParams = new URLSearchParams(this.props.location.search);
    const addBaggageExists = currentSearchParams.has("addBaggage");
    if (addBaggageExists) {
      currentSearchParams.delete("addBaggage");
    } else {
      currentSearchParams.set("addBaggage", "true");
    }
    this.props.history.replace({
      pathname: this.props.location.pathname,
      search: currentSearchParams.toString(),
    });
  };

  render() {
    const { SettingList = {} } = this.props;
    const { flight_booking_terms_condition = "" } = SettingList;
    const { fb_tc_checboxlabel = "" } = SettingList;
    const { loading, fareRule } = this.state;
    const {
      airportName,
      airlineName,
      flight_details,
      BookingInfo,
      TotalPrice,
      price,
    } = { ...this.state.flightDetails, ...this.state.AmaNode } || {};
    let checkGoogleFlightsUrl =
      this.props.history?.location?.pathname?.includes("googleresults");
    const queryData = this.props.location.search
      ? qs.parse(this.props.location.search?.substring(1))
      : "";
    let cabinData = queryData?.Cabin1
      ? CABIN_OPTIONS.find((option) => option.label === queryData?.Cabin1)
        ?.value
        ? CABIN_OPTIONS.find((option) => option.label === queryData?.Cabin1)
          ?.value
        : flight_details?.inwardSegment?.[0]?.cabin
          ? CABIN_OPTIONS.find(
            (option) =>
              option.label === flight_details?.inwardSegment?.[0]?.cabin
          )?.value
          : undefined
      : undefined;
    let queryParams = {
      from: flight_details?.from || undefined,
      to: flight_details?.to || undefined,
      child: BookingInfo?.Children || 0,
      infant: BookingInfo?.Infants || 0,
      adult: BookingInfo?.Adults || 0,
      arrival: BookingInfo?.LastDate
        ? moment(BookingInfo?.LastDate).format("YYYY-MM-DD")
        : undefined,
      depart: BookingInfo?.FirstDate
        ? moment(BookingInfo?.FirstDate).format("YYYY-MM-DD")
        : undefined,
      trip: flight_details?.type === "RoundTrip" ? 0 : 1,
      cabin: checkGoogleFlightsUrl
        ? cabinData
        : queryData?.getFlightInfo?.searchinfo?.cabin,
      utm_campaign: queryData?.utm_campaign || "localSearch",
      requestId: this.state.cheapestData?.requestId,
      // isDirect: this.state.cheapestData?.isDirect
    };

    return (
      <>
        <FlightLoader show={loading} bookNow={true} />
        {this.state.showPopup && (
          <RefreshModal
            show={this.state.showPopup}
            onRefresh={() =>
              this.props.history.push(`search?${qs.stringify(queryParams)}`)
            }
          />
        )}
        <div className="bg-light">
          <div className="py-4 container">
            {!this.props.history.location.pathname.includes("googleresults") &&
              Math.round(flight_details?.offerPrice - price?.total) > 1 ? (
              <div className="theme-flight-detail-main-heading">
                <i class="fa fa-check-circle theme-checkout-message-icon"></i>
                <span className="theme-flight-detail-heading">
                  Congratulations! you have saved £
                  {Math.round(flight_details?.offerPrice - price?.total)} on
                  this booking
                </span>
              </div>
            ) : (
              <div></div>
            )}
            {TotalPrice ? (
              <>
                <h4 className="flight-detail-heading row">
                  <div className="col-sm-8">
                    Your Flight details
                    <div className="price-gua-mobile">
                      <BaggageNotification
                        addBaggage={this.state.addBaggage}
                        baggageNoti={this.state.baggageNotification}
                        onClose={() =>
                          this.setState({ baggageNotification: false })
                        }
                        marginTop={true}
                      />
                      <PriceGuarantee
                        queryParams={queryParams}
                        checkGoogleFlightsUrl={checkGoogleFlightsUrl}
                        cheapestData={this.state.cheapestData}
                        marginTop={true}
                      />
                    </div>
                  </div>
                </h4>
              </>
            ) : (
              ""
            )}
            <div className="row">
              <div className="col-sm-4 trip-summary">
                {TotalPrice ? (
                  <>
                    <TripSummary
                      // pricingInfo={PricingInfo}
                      adultFare={flight_details?.AdultFares}
                      childFare={flight_details?.ChildFares}
                      infantFare={flight_details?.InfantFares}
                      bookingFare={flight_details?.bookingFares}
                      totalPrice={TotalPrice}
                      taxPrice={0}
                      baggagePrice={
                        this.state.addBaggage ? this.state.baggagePrice : 0
                      }
                      baggageBasePrice={this.state.baggageBasePrice}
                      addBaggage={this.state.addBaggage}
                    />
                    {flight_details?.type === "SingleTrip" ? (
                      <div className="price-gua-desktop">
                        <CheckInBaggage
                          flight_details={flight_details}
                          addBaggage={this.state.addBaggage}
                          addBaggageFunc={this.addBaggageFunc}
                          baggagePrice={this.state.baggagePrice}
                          baggageBasePrice={this.state.baggageBasePrice}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    <div className="row web-only">
                      <div className="col-12">
                        <button
                          className="btn continue-button"
                          onClick={this.handleBookFlight}
                        >
                          <a className="p-2 font-weight-bold text-white">
                            Continue Booking
                          </a>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div></div>
                )}
                <div className="custom-side help-section">
                  <p>Why book with us?</p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/Union 1.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>Best Price Guaranteed</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/images/atol.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>ATOL Protected</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/images/refer-and-earn.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>Refer & Earn Unlimited</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/Union 2.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>Discounted Fares</div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/images/happy-face.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>90% Happy Repeat Clients</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/images/Book-img.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>Book with £50 per person</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-8 flight-details">
                {flight_details?.type === "SingleTrip" ? (
                  <div className="price-gua-mobile">
                    <CheckInBaggage
                      flight_details={flight_details}
                      addBaggage={this.state.addBaggage}
                      addBaggageFunc={this.addBaggageFunc}
                      baggagePrice={this.state.baggagePrice}
                      baggageBasePrice={this.state.baggageBasePrice}
                    />
                  </div>
                ) : (
                  <></>
                )}
                <p className="financially-alot-mobile">
                  Your flight is 100% financially protected with ATOL.
                </p>
                <div className="flight-pax _mb-15">
                  {/* {flight_details && flight_details.type !== "RoundTrip" ? (
                                        <SingleTrip
                                            {...this.props}
                                            flightLists={{data: flight_details ? [flight_details] : []}}
                                            airportName={airportName || []}
                                            fareRule={fareRule}
                                        />
                                    ) : (
                                        <RoundTrip
                                            {...this.props}
                                            flightLists={{data: flight_details ? [flight_details] : []}}
                                            airportName={airportName || []}
                                            fareRule={fareRule}
                                        />
                                    )}*/}
                  <div className="price-gua-desktop">
                    <BaggageNotification
                      addBaggage={this.state.addBaggage}
                      baggageNoti={this.state.baggageNotification}
                      onClose={() =>
                        this.setState({ baggageNotification: false })
                      }
                    />
                    <PriceGuarantee
                      queryParams={queryParams}
                      checkGoogleFlightsUrl={checkGoogleFlightsUrl}
                      cheapestData={this.state.cheapestData}
                    />
                  </div>
                  <SearchResult
                    {...this.props}
                    airportNames={airportName}
                    airlineNames={airlineName}
                    fareRule={fareRule}
                    flightList={{
                      data: flight_details ? [flight_details] : [],
                      oneWay:
                        flight_details && flight_details.type === "SingleTrip",
                    }}
                  />
                  <div id="paySection" />
                </div>
                <p className="financially-alot-desktop">
                  Your flight is 100% financially protected with ATOL.
                </p>
                <div className="flight-pax">
                  <h4 className="traveller-detail-heading">
                    {" "}
                    Traveller's detail
                  </h4>
                  <div className="card passenger-detail-card">
                    <div className="card-body">
                      <div className="ng-pristine ng-invalid ng-touched">
                        <div
                          formarrayname="passengers"
                          className="ng-untouched ng-pristine ng-invalid"
                        >
                          {/* <PassengerDetail {...props} /> */}
                          <h4 className="traveller-form-heading">
                            Contact Information
                          </h4>
                          {/* <div className="text-secondary">
                            <small className="helpText">
                              Please provide contact details aligned with the payment card which
                              will be used for payment, these details will be populated on the
                              Merchant payment gateway website.
                            </small>
                          </div> */}
                          <form className="_w-100pct _one-line-flex">
                            <div className="form-group _pt-5 _pr-5 _pl-5 _w-100pct">
                              <label htmlFor="email" className="label-text">
                                Email <span className="red-mask">*</span>{" "}
                              </label>
                              <input
                                type="email"
                                id="email"
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                onChange={this.handleInfoChange}
                                value={this.state.email || ""}
                              />
                              <div className="text-secondary">
                                <small className="helpText">
                                  Your booking confirmation will be sent to this
                                  email address.
                                </small>
                              </div>
                              {this.validator.message(
                                "email",
                                this.state.email,
                                "required|email",
                                { messages: { required: "Email is required" } },
                                { className: "text-danger" }
                              )}
                            </div>
                            <div className="form-group _pt-5 _pr-5 _pl-5 _w-100pct">
                              <label htmlFor="contact" className="label-text">
                                Contact Number{" "}
                                <span className="red-mask">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="contact"
                                placeholder="Enter number"
                                name="contact"
                                onChange={this.handleInfoChangeContact}
                                value={this.state.contact || ""}
                                minLength={10}
                                maxLength={20}
                              />
                              <div className="text-secondary">
                                <small className="helpText">
                                  We ask for your contact number so we can call
                                  or text you about changes to your itinerary.
                                </small>
                              </div>
                              {this.validator.message(
                                "contact",
                                this.state.contact,
                                "required|max:20|min:10",
                                {
                                  messages: {
                                    required: "Contact Number is required",
                                    max: "Contact Number must be a valid 20 digits",
                                    min: "Contact Number must be a valid 10 digits",
                                  },
                                },
                                { className: "text-danger" }
                              )}
                            </div>
                          </form>
                          <hr />
                          <span>
                            <h4 className="traveller-form-heading pax-details">
                              Passengers Details
                            </h4>{" "}
                            <h6 className="pax-details">
                              (Please enter all details as shown on the
                              passport.)
                            </h6>
                          </span>
                          {BookingInfo && BookingInfo.Adults
                            ? [...Array(BookingInfo.Adults).keys()].map(
                              (_, i) => this.userInfo("Adult", i, "A", i)
                            )
                            : null}
                          {BookingInfo && BookingInfo.Children
                            ? [...Array(BookingInfo.Children).keys()].map(
                              (_, i) =>
                                this.userInfo(
                                  "Children",
                                  i + BookingInfo.Adults,
                                  "C",
                                  i
                                )
                            )
                            : null}
                          {BookingInfo && BookingInfo.Infants
                            ? [...Array(BookingInfo.Infants).keys()].map(
                              (_, i) =>
                                this.userInfo(
                                  "Infants",
                                  i +
                                  BookingInfo.Adults +
                                  BookingInfo.Children,
                                  "I",
                                  i
                                )
                            )
                            : null}
                          <hr />




                          <div className="terms-heading" onClick={this.showTnc}>
                            <h4 className="traveller-form-heading">
                              Please read this Important Information
                            </h4>
                            <i
                              class="fa fa-chevron-down"
                              aria-hidden="true"
                            ></i>
                          </div>
                          <span
                            style={{ margin: "0 0 15px", display: "none" }}
                            ref={this.tncRef}
                            dangerouslySetInnerHTML={{
                              __html: flight_booking_terms_condition,
                            }}
                          />
                          <div className="terms-accepts">
                            <input
                              type="checkbox"
                              id="terms-condition"
                              name="terms-condition"
                              onChange={this.handleTcChange}
                              checked={this.state.tc || false}
                            />
                            <label
                              for="terms-condition"
                              style={{
                                marginLeft: 10 + "px",
                                verticalAlign: "middle",
                              }}
                            >
                              {fb_tc_checboxlabel}
                              {this.state.acceptTC ? (
                                <div
                                  className="srv-validation-message"
                                  style={{ fontWeight: "normal" }}
                                >
                                  Please accept our terms and conditions
                                </div>
                              ) : (
                                ""
                              )}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="paySection" />
                </div>
                <div className="row mobile-only">
                  <div className="col-12">
                    <button
                      className="btn continue-button"
                      onClick={this.handleBookFlight}
                    >
                      <a className="p-2 font-weight-bold text-white">
                        Continue Booking
                      </a>
                    </button>
                  </div>
                </div>
                <div className="custom-side help-section mobile-only-block">
                  <p>Why book with us?</p>
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/Union 1.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>Best Price Guaranteed</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/images/atol.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>ATOL Protected</div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/images/refer-and-earn.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>Refer & Earn Unlimited</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/Union 2.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>Discounted Fares</div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ justifyContent: "center" }}>
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/images/happy-face.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>90% Happy Repeat Clients</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="book_with_us">
                        <img
                          src={"/assets/ficon/images/Book-img.png"}
                          alt="no-fee"
                          className="book-with-us-icon"
                        />
                        <div>Book with £50 per person</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const FlightDetailsContainer = (props) => {
  const data = useContext(FlightDetailsContext);
  return <FlightDetails {...props} {...data} />;
};
export default FlightDetailsContainer;
