import React, { useState, useContext, useEffect } from "react";

import LoginContext from "../../Context/Context";
import UserService from "../../Service/UserService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import base64Img from "base64-img";

import { LoginUtils } from "../../Service/UtilsService";
import { Link } from "react-router-dom";

const Login = (props) => {
  let userServ = new UserService();
  let LoginContxt = useContext(LoginContext);
  const [Banner, setBanner] = useState([]);
  const [login, setLogin] = useState({
    email: "",
    password: "",
  });
  useEffect(() => {
    window.scroll(0, 0);
    async function fetchData() {
      const result = await LoginUtils();
      if (sessionStorage.getItem("login")) {
        result.bannerimage = sessionStorage.getItem("login");
        setBanner(result);
      } else {
        base64Img.requestBase64(result.bannerimage, function (err, res, body) {
          sessionStorage.setItem("login", body);
          result.bannerimage = sessionStorage.getItem("login");
          setBanner(result);
        });
      }
    }
    fetchData();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setLogin((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    userServ
      .login(login)
      .then((response) => {
        sessionStorage.setItem("user", JSON.stringify(response));
        window.user = response;
        toast.success(response.message);
        LoginContxt.login(true);
        setTimeout(() => {
          props.history.push("/");
        }, 1000);
      })
      .catch((error) => {
        toast.error(error);
        LoginContxt.login(false);
      });
  };
  return (
    <React.Fragment>
      {/* <div id="backdrop"></div>
        <div id="modal">
          <div className="loader">
            <i class="fa fa-circle-o-notch fa-spin fa-4x" style={{ color: "white" }} aria-hidden="true"></i>
          </div>
        </div> */}
      <div className="theme-hero-area">
        <div className="theme-hero-area-bg-wrap">
          <div className="theme-hero-area-bg" style={{ backgroundImage: `url(${Banner.bannerimage})` }}></div>
          <div className="theme-hero-area-mask theme-hero-area-mask-strong"></div>
        </div>
        <div className="theme-hero-area-body">
          <div className="theme-page-section _pt-100 theme-page-section-xl">
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-md-offset-4">
                  <div className="theme-login theme-login-white">
                    <div className="theme-login-header">
                      <h1 className="theme-login-title">{Banner.title}</h1>
                      <p className="theme-login-subtitle">{Banner.subtitle}</p>
                    </div>
                    <div className="theme-login-box">
                      <div className="theme-login-box-inner">
                        <form className="theme-login-form">
                          <div className="form-group theme-login-form-group">
                            <input
                              className="form-control"
                              type="text"
                              name="email"
                              placeholder="Email Address"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                          </div>
                          <div className="form-group theme-login-form-group">
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              placeholder="Password"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                            />
                            <p className="help-block">
                              <Link to="/forgotpassword">Forgot password?</Link>
                            </p>
                          </div>
                          <div className="form-group">
                            <div className="theme-login-checkbox">
                              <label className="icheck-label">
                                <input className="icheck" type="checkbox" />
                                <span className="icheck-title">Keep me logged in</span>
                              </label>
                            </div>
                          </div>
                          <button className="btn btn-uc btn-dark btn-block btn-lg" onClick={handleSubmit}>
                            Sign In
                          </button>
                        </form>
                        {/* <div className="theme-login-social-separator">
                        <p>or sign in with social media</p>
                      </div>
                      <div className="theme-login-social-login">
                        <div className="row" data-gutter="10">
                          <div className="col-xs-6">
                            <a
                              className="theme-login-social-login-facebook"
                              href="#"
                            >
                              <i className="fa fa-facebook-square"></i>
                              <span>
                                Sign in with
                                <br />
                                <b>Facebook</b>
                              </span>
                            </a>
                          </div>
                          <div className="col-xs-6">
                            <a
                              className="theme-login-social-login-google"
                              href="#"
                            >
                              <i className="fa fa-google-plus-circle"></i>
                              <span>
                                Sign in with
                                <br />
                                <b>Google</b>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div> */}
                      </div>
                      <div className="theme-login-box-alt">
                        <p>
                          Don't have an account? &nbsp;
                          <Link to="/register">Sign up.</Link>
                        </p>
                      </div>
                    </div>
                    <p className="theme-login-terms">
                      By logging in you accept our
                      <a href="#">terms of use</a>
                      <br />
                      and
                      <a href="#">privacy policy</a>.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<ToastContainer />*/}
      </div>
    </React.Fragment>
  );
};
export default Login;
